import { useEffect, useState } from "react";
import { Student, NavLink, Mark } from "../Interfaces";
import axios from "axios";
import { LinkItem } from "../dashboard/NavBar";
import { useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import Template from "../Template";
import { spinner } from "../../utils/customizeAxios";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;
const imgPlaceholder = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7">
                        <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clipRule="evenodd" />
                    </svg>  


export default function Marksheet(){
    return(<Template children={<Page />} />);
}

function Page(){
    const [students, setStudents] = useState<Student[]>();
    const [markObjArr, setMarkObjArr] = useState<Mark[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [isLoading3, setIsLoading3] = useState(false)
    const {authUser} = useAuth();

    const apiUrl = process.env.REACT_APP_API_URL;
    const {classId, type} = useParams();

//links on the top navbar
const links: NavLink[] = [
    {pathName: '/marksheet/' + classId + '/mid', name: 'Mid Term'},  
    {pathName: '/marksheet/' + classId + '/end', name: 'End of Term'},  
];

    const fetchStudents = async () => {
        setIsLoading2(true)
        await axios.get(`${apiUrl}/class/students/${classId}`)
        .then(r => {
            setStudents(r.data);
        }).catch(e => console.error("Failed to fetch: " + e));
        setIsLoading2(false)
    };

    const fetchMarkObjArr = async () => {
        setIsLoading3(true)
        await axios.post(`${apiUrl}/specifiedMark2/${type}`, 
            {studentIds: students?.map(s => s.id), subjectIds: authUser?.subjects?.map(s => s.id)}
        )
        .then(r => {
            setMarkObjArr(r.data);
        }).catch(e => console.error("Failed to fetch: " + e));
        setIsLoading3(false)
    }

    useEffect(() => {
        fetchStudents()
}, [classId]);

    useEffect(() => {
        if(students && students?.length > 0)
            fetchMarkObjArr()
    }, [type, students]);

    console.log(markObjArr)
    const handleSubmit = async () => {
        setIsLoading(true)
        
        await axios.post(apiUrl + '/record-mark-bulk', {payload: markObjArr})
        .then(r => alert("Updated succesfuly"))
        .catch(e => console.error(e));

        setIsLoading(false)
    }
    
    return(
        <>
            <nav className="bg-purple-300 text-slate-500 text-lg">
                <ul className="flex p-1 justify-between">
                    {links.map(l => 
                        <li><LinkItem l={l} id={classId}/></li>    
                    )}
                </ul>
            </nav>
            <div className="rounded-lg w-[98%] min-h-[85%] ring-purple-600 mt-2 p-2 mx-auto bg-purple-100 ring-1">
                {isLoading ?
                <p className="inputstyle cursor-wait w-full m-2">Please wait...</p>
                :
                <button className="inputstyle w-full my-2" onClick={() => handleSubmit()}>Save</button>
                }
                <table className="w-full">
                    <thead>
                        <tr className="backdrop-blur-md text-left bg-purple-300">
                            <th className="p-3">Name</th>
                            {authUser?.subjects?.map(subject => 
                                <th>{subject.name}</th>    
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading2 ?
                        <div className="min-h-screen flex justify-center">Please wait...{spinner}</div>
                        :
                        students?.map(student => 
                            <tr className="" key={student.id}>
                                <td className="p-2 flex">
                                {student.profile_pic_filepath === null ? imgPlaceholder
                                :
                                    <img src={`${imgUrl}/${student.profile_pic_filepath}`} className="w-7 h-7 rounded-full mr-1" alt="photo" />
                                }
                                    {student.name}
                                </td>
                                
                                {isLoading3 ?
                                    spinner
                                :
                                authUser?.subjects?.map(subject => 
                                
                                    <MarkInput student_id={student.id?.toString()} key={subject.id}
                                        subject_id={subject.id?.toString()} loading={isLoading3}
                                        type={type} markObjArr={markObjArr} setmarkObjArr={setMarkObjArr}/>
                                )}
                            </tr>
                        )
                        }
                        
                    </tbody>
                </table>
                {isLoading ?
                <p className="inputstyle cursor-wait">Please wait...</p>
                :
                <button className="inputstyle w-full" onClick={() => handleSubmit()}>Save</button>
                }
            </div>
        </>
    );
}

interface MarkInputInterface extends Mark {
    markObjArr: Mark[]
    loading: boolean
    setmarkObjArr: React.Dispatch<React.SetStateAction<Mark[]>>
}

function MarkInput(i: MarkInputInterface) {
    const [markObj, setMarkObj] = useState<Mark>({student_id: i.student_id, 
                                                subject_id: i.subject_id, 
                                                type: i.type, mark: undefined });
    const [inputLabel, setInputLabel] = useState('Ok')

    useEffect(() => {
        const mark = i.markObjArr.find(m => m.type == markObj.type && m.student_id == markObj.student_id 
            && m.subject_id == markObj.subject_id)
    
            //setMarkObj(mark!)
            
            if(mark){
                setMarkObj(mark)
            } else {
                setMarkObj({student_id: i.student_id, 
                    subject_id: i.subject_id, 
                    type: i.type, mark: 0 })
            }
            
    }, [i.loading])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) < 0 || Number(e.target.value) > 100) {
            setInputLabel('Invalid')
            return  
        }
        const interim = {...markObj, mark: Number(e.target.value)}
        setMarkObj(interim)

        const index = i.markObjArr.findIndex(m => m.student_id === markObj.student_id 
            && m.subject_id === markObj.subject_id)

        if(index !== -1) {
            const updatedMarkObjArr = [...i.markObjArr]
            updatedMarkObjArr[index] = {...updatedMarkObjArr[index], mark: Number(e.target.value)}
            i.setmarkObjArr(updatedMarkObjArr)
        }else{
            i.setmarkObjArr([...i.markObjArr!, interim])
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        axios.post(apiUrl + '/record-mark', markObj)
        .then(r => setInputLabel('Sent'))
        .catch(e => console.error(e));
    };

    return(
        <td className="">
        <form onSubmit={e => handleSubmit(e)}>
            <input type="number" name="mark"
                className="inputstyle w-1/2" 
                value={markObj.mark} min={0} max={100}
                onChange={e => handleInputChange(e)}
            />
            {/*<input type="submit" name="Submit" value={inputLabel} className="inputstyle"/>*/}
        </form>
        </td>
    );
}